import React from "react"
import { Slide } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import StackedCarousel from "./StackedCarousel"
import TextCard from "./TextCard"
import VideoCard from "./VideoCard"

export const CardCarousel = ({ cards }) => {
  return (
    <StackedCarousel
      naturalSlideWidth={9}
      naturalSlideHeight={16}
      totalSlides={cards.length}
    >
      {cards.map((card, index) => {
        if (card.type === "text") {
          return (
            <Slide index={index}>
              <TextCard
                index={index}
                title={card.title}
                buttonText={card.buttonText}
                backgroundColor={props => props.theme[card.backgroundColor]}
                textColor={props => props.theme[card.textColor]}
                text={typeof card.text === "function" ? card.text() : card.text}
                link={card.to}
                withSkip={card.withSkip}
              />
            </Slide>
          )
        } else if (card.type === "video") {
          return (
            <Slide index={index}>
              <VideoCard
                url={card.url}
                index={index}
                to={card.to}
                linkText={card.linkText}
              />
            </Slide>
          )
        } else {
          return null
        }
      })}
    </StackedCarousel>
  )
}
