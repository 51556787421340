import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { ButtonLast, CarouselContext } from "pure-react-carousel"
import styled from "@emotion/styled"
import ReactPlayer from "react-player/vimeo"

import PlayButton from "../../images/utility/play-button.svg"
import { useViewableHeight } from "../../hooks/useViewableHeight"
import ButtonLink from "../form/ButtonLink"

const Card = styled.div`
  position: relative;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : "black"};
  border-radius: ${props => props.theme.radius.xl};
  padding: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  user-select: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: calc(${props => props.viewableHeight}px - 100px);
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${props => props.theme.radius.xl};
  }
`
const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url(${PlayButton}) no-repeat center;
  transition: 1s;
  opacity: ${props => (props.playing ? "0" : "1")};
`

const ButtonContainer = styled.div`
  z-index: 1;
  display: grid;
  position: absolute;
  bottom: 32px;
`

const SkipButton = styled(ButtonLast)`
  background: transparent;
  z-index: 100;
  border: none;
  padding: 12px 32px;
  color: ${props => (props.textColor ? props.textColor : "white")};
`

export default function VideoCard({
  url,
  to,
  linkText,
  textColor,
  index,
  withSkip = true,
}) {
  const [playing, setPlaying] = useState(false)
  const viewableHeight = useViewableHeight()
  const player = useRef(null)
  const carouselContext = useContext(CarouselContext)

  const finishedPlaying = () => {
    setPlaying(false)
    if (player?.seekTo) {
      player.seekTo(0, "seconds")
    }
  }
  useEffect(() => {
    function onChange() {
      if (carouselContext.state.currentSlide !== index) {
        finishedPlaying()
      }
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext, index])

  return (
    <Card textColor={textColor} viewableHeight={viewableHeight}>
      <ReactPlayer
        ref={player}
        url={url}
        playing={playing}
        onEnded={finishedPlaying}
        onPause={() => setPlaying(false)}
      />
      <Overlay
        onClick={() => setPlaying(!playing)}
        aria-label="Play"
        playing={playing}
      />
      {!to && (
        <>{withSkip && <SkipButton textColor={textColor}>Skip</SkipButton>}</>
      )}

      {to && (
        <ButtonContainer>
          <ButtonLink
            to={to}
            buttonStyle={"inverted"}
            backgroundColor={props => props.theme.apricot}
          >
            {linkText}
          </ButtonLink>
        </ButtonContainer>
      )}
    </Card>
  )
}

VideoCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  withSkip: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  link: PropTypes.string,
}
