import React from "react"
import Layout from "../../components/global/layout"
import { CardCarousel } from "../../components/block/CardCarousel"
import { urls } from "../../data/urls"
import styled from "@emotion/styled"

const SmallText = styled.p`
  font-size: 10px;
  line-height: 1.2;
  padding-bottom: 20px;
`

const cards = [
  {
    type: "text",
    to: null,
    title:
      "As you go through life, you'll notice small changes in your vagina and vulva but that's normal.",
    backgroundColor: "peach",
    textColor: "wine",
    withSkip: true,
  },
  {
    type: "text",
    to: null,
    title:
      "Hormones are often the culprit; they fluctuate through the month and throughout your life, starting with puberty and your first period.",
    backgroundColor: "apricot",
    textColor: "wine",
    withSkip: true,
  },
  {
    type: "text",
    to: null,
    title:
      "Pregnancy, breastfeeding and the menopause are also significant periods of hormonal fluctuation.",
    backgroundColor: "wine",
    textColor: "apricot",
    withSkip: true,
  },
  {
    type: "text",
    to: null,
    title:
      "All of those hormonal changes can lead to changes in your vagina and vulva, as well.",
    backgroundColor: "apricot",
    textColor: "wine",
    withSkip: true,
  },
  {
    type: "text",
    title:
      "To keep your vulva healthy and happy, knowledge is power. So how much do you know? Try our true or false quiz and get up on the facts.",
    backgroundColor: "apricot",
    textColor: "wine",
    withSkip: false,
    to: urls.VH.QUIZ,
    linkText: "Take the quiz!",
    text: () => (
      <SmallText>
        Canesten Thrush Combi Pessary & External Cream. Contains clotrimazole.
        Always read the label. Canesoasis Cystitis Relief 4g granules for Oral
        Solution. Contains sodium citrate. Always read the label
      </SmallText>
    ),
  },
]

export default function index() {
  return (
    <Layout>
      <CardCarousel cards={cards} />
    </Layout>
  )
}
