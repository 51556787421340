import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { CarouselProvider, Slider, DotGroup } from "pure-react-carousel"

const Container = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  overflow: hidden;
  /* max-width constrains the width of our carousel to 400, but shrinks on small devices */
  .carousel__container {
    max-width: 400px;
    margin: auto;
  }

  /* gives us the illusion of a "centered" slide */
  .carousel__slider {
    padding-left: 20px;
    padding-right: 20px;
  }
  .carousel__slide {
  }

  /* gives us the illusion of spaces between the slides */
  .carousel__inner-slide {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
  }
  .carousel__dot {
    background: #ffffff;
    width: 12px;
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 12px;
    transition: 0.4s;
    + * {
      margin-left: 8px;
    }
  }
  .carousel__dot--selected {
    background-color: ${props => props.theme.teal};
  }
  .carousel__dot-group {
    display: block;
  }
  
  .carousel__slider-tray-wrap--horizontal {
    margin-bottom: 10px;
  }
`
const CenteredDots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -10px;
`

export default function StackedCarousel({
  children,
  naturalSlideWidth,
  naturalSlideHeight,
  totalSlides,
}) {
  return (
    <Container>
      <CarouselProvider
        naturalSlideWidth={naturalSlideWidth}
        naturalSlideHeight={naturalSlideHeight}
        isIntrinsicHeight={true}
        totalSlides={totalSlides}
        lockOnWindowScroll={true}
      >
        <Slider>{children}</Slider>
        <CenteredDots>
          <DotGroup />
        </CenteredDots>
      </CarouselProvider>
    </Container>
  )
}

StackedCarousel.propTypes = {
  children: PropTypes.element,
  naturalSlideWidth: PropTypes.number,
  naturalSlideHeight: PropTypes.number,
  totalSlides: PropTypes.number,
}
